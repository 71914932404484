import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Capricorn.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
              Capricorn Facts
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/capricorn"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Capricorn </h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Capricorn Facts</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Capricorn Facts</h1>
                <h2 className="text-sm md:text-base ml-4">Dec 22 - Jan 19</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Capricorn Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-love");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-nature");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-man");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-traits");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/capricorn/capricorn-facts");
                  }}>
                  <h5 className="font-semibold text-black">Capricorn Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Capricorn Facts</p><br/>
<p className="font-bold text-orange-500 inline-block mr-2 ">Check capricorn horoscope in detail:</p>
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Ruling Planet:</p>Saturn
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Capricorn Traits:</p> Capricorn-born people are the most determined of the entire Zodiac. The most prominent qualities of the Goats, as they are called, are that they are ambitious, conservative, determined, practical, and realistic. They are also very good at planning and setting goals, and they work hard to achieve them. They do not like to show their emotions, and they do not want to be the center of attention.<br/><br/>
They are also quite confident and tend to achieve whatever they set their minds to. It's a good thing because the more determined and sure you are, the more likely you will achieve your goals and realize your dreams.
 <br/><br/>
Although hard-working, they make good team leaders and organizers because of their single-minded focus on their work, sense of responsibility, and sincerity. They are perceived by people around them to be workaholics, unable to multi-task, and are often late. They are great at managing and motivating themselves but not good at managing and inspiring others.
 <br/><br/>
Sometimes their negative qualities – suspicious, resentful, inhibited, pessimistic and stubborn – are seen clearly, but deep inside the Goat is a humble heart. Rejection is never a good feeling, but it's a reality for people with sensitive skin. People with sensitive skin are often described as "easily hurt" or "hurt easily," and their skin is softer and more prone to redness than others. They are very gentle by nature. They don't accept rejection. 
 <br/><br/>
If you want to know more about your positive or negative traits, you may connect to our best astrologers with remedial solutions for personal issues.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lucky Day:</p>Saturday
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lucky Number :</p>1, 4, 8, 10, 13, 17, 19, 22, 26
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lucky Colour:</p> Brown, Steel, Grey, Black
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lucky Stone(s):</p> Dark Sapphire (The gemstone is suggested considering Aries as the Ascendant/Lagna Sign)
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Lucky Talisman:</p>The Plough
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Positive Qualities:</p> Faithful, Ambitious, Self-controlled, Persistent, Responsible, Honest<br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Negative Qualities: </p>Shy, Pessimistic, Awkward, Independent, Self-centred, Gloomy, Determined.
 <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Possible Health Concern:</p> Workaholics as they are, natives of this Sign can harm their health by stretching their bodies beyond their limits. They may overlook the point at which they need to slow down, which can be self-destructive. The key to suitable health for them lies in being fair to themselves. They should protect their health by being punctual about meals, labor, amusement, exercise, and sleep. If you feel that you cannot operate at your full potential, try the completely personalized Birth Chart based service Chart your destiny.
 <br/><br/>
 <p className="font-bold text-orange-500 inline-block mr-2">Compatible Signs:</p>Taurus, Cancer, Virgo, and Capricorn. <br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Famous Personalities:</p> Marilyn Monroe, Elvis Presley, Mansur Ali Khan Pataudi, Mohammed Rafi, Sir Isaac Newton, Anwar Sadat, Benjamin Franklin, Denzel Washington, Elvis Presley, Henry Miller, Gamal Abdel Nasser, Hamid Karzai. You might also be interested in reading about Capricorn traits.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
